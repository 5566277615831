import times from 'lodash/times';

import { cn, IconButtonAsButton, Input } from '../../../v2';
import { IconChevronLeft, IconChevronRight } from '../../icons';
import type { CalendarHook } from '../hooks/calendar';

interface Props {
  minYear?: number;
  maxYear?: number;
  calendarHook: CalendarHook;
}

const monthsInOrder = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function Caret(): JSX.Element {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 8.1875H8.25L4.125 13L0 8.1875Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 4.8125H8.25L4.125 0L0 4.8125Z" fill="currentColor" />
    </svg>
  );
}

export default function CalendarHeader(props: Props): JSX.Element {
  const { maxYear = 9999, minYear = 1000, calendarHook } = props;
  const { increaseMonth, decreaseMonth, setYear, setMonth, year, month } = calendarHook;

  return (
    <div className="flex w-full items-center justify-between">
      <IconButtonAsButton onClick={decreaseMonth} className="text-muted-foreground">
        <IconChevronLeft color="currentColor" />
      </IconButtonAsButton>
      <div className="flex w-full flex-1 items-center p-1">
        <div className="relative flex-[1.5]">
          <select
            className={cn(
              'min-w-[100px] appearance-none bg-transparent text-base font-semibold',
              'rounded-sm border border-transparent focus:outline-none dark:text-foreground',
              'pb-0 pl-1 pr-4 pt-0 hover:border-border focus:border-brand-400',
            )}
            value={String(month)}
            onChange={(e) => setMonth(parseInt(e.target.value, 10))}
          >
            {times(12, (num) => {
              return <option value={num + 1}>{monthsInOrder[num]}</option>;
            })}
          </select>
          <div className="absolute right-2 top-[50%] translate-y-[-50%] text-steel-300">
            <Caret />
          </div>
        </div>
        <div className="flex flex-1">
          <Input
            min={minYear}
            max={maxYear}
            value={year}
            onChange={(e) => {
              if (e.target.value !== '') {
                setYear(parseInt(e.target.value, 10));
              }
            }}
            type="number"
            wrapperClassName="h-auto border-transparent hover:border-border"
            inputClassName="py-0 px-1 font-semibold"
          />
        </div>
      </div>
      <IconButtonAsButton onClick={increaseMonth} className="text-muted-foreground">
        <IconChevronRight color="currentColor" />
      </IconButtonAsButton>
    </div>
  );
}
