import * as React from 'react';

import { TextEyebrow } from '../../../v2/components/text-eyebrow';

interface Props {
  children: React.ReactNode;
}

export function MenuItemTitle(props: Props): JSX.Element {
  const { children } = props;
  /**
   * We use margins instead of padding so that they collapse. This makes it easier for the
   * spacing to work when there are headers and dividers next to each other AND when they're
   * rendered individually.
   */
  return (
    <div className="mb-2 mt-4 px-6 py-0">
      <TextEyebrow>{children}</TextEyebrow>
    </div>
  );
}
