import { AuthRole, EMPLOYEE_ROLES, PRODUCER_ROLES } from '@newfront-insurance/auth-api';
import { hasAnyScope } from '@newfront-insurance/next-auth';
import type { FunctionComponent } from 'react';

import type { AppIconProps } from '../components/app-icons/icons';
import {
  BillingIcon,
  AccountsIcon,
  CertificatesIcon,
  ClaimsIcon,
  ConversionIcon,
  ExposuresIcon,
  FilesIcon,
  QuotingIcon,
  TradingPartnersIcon,
  CoverageGapAnalysisIcon,
} from '../components/app-icons/icons';

export interface AppMetadata {
  label: string;
  description: string;
  href: string;
  type: AppType;
  scopes: AuthRole[];
  showInMenu: boolean;
  icon: FunctionComponent<AppIconProps>;
}

export enum AppType {
  'ACCOUNTS' = 'ACCOUNTS',
  'CERTIFICATES' = 'CERTIFICATES',
  'CLAIMS' = 'CLAIMS',
  'CONVERSION' = 'CONVERSION',
  'COVERAGE_GAP_ANALYSIS' = 'COVERAGE_GAP_ANALYSIS',
  'EXPOSURES' = 'EXPOSURES',
  'FILES' = 'FILES',
  'QUOTING' = 'QUOTING',
  'TRADING_PARTNERS' = 'TRADING PARTNERS',
  'BILLING' = 'BILLING',
}

export const APP_METADATA: Record<AppType, AppMetadata> = {
  [AppType.ACCOUNTS]: {
    label: 'Accounts',
    description: 'View and manage your accounts',
    href: '/accounts',
    type: AppType.ACCOUNTS,
    icon: AccountsIcon,
    showInMenu: true,
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      AuthRole.GROWTH_CALLER,
      AuthRole.OPERATIONS,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.PRODUCER,
      AuthRole.RISK_MANAGER,
      AuthRole.SALES_MANAGER,
    ],
  },
  [AppType.CERTIFICATES]: {
    label: 'Certificates',
    description: 'Generate and download certificates',
    href: '/growth',
    type: AppType.CERTIFICATES,
    icon: CertificatesIcon,
    showInMenu: false,
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      // AuthRole.GROWTH_CALLER,
      AuthRole.OPERATIONS,
      // AuthRole.PRODUCER_MANAGER,
      // AuthRole.PRODUCER,
      // AuthRole.RISK_MANAGER,
      // AuthRole.SALES_MANAGER,
    ],
  },
  [AppType.CLAIMS]: {
    label: 'Claims',
    description: 'Track and manage claims',
    href: '/claims',
    type: AppType.CLAIMS,
    icon: ClaimsIcon,
    showInMenu: false,
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      // AuthRole.GROWTH_CALLER,
      AuthRole.OPERATIONS,
      // AuthRole.PRODUCER_MANAGER,
      // AuthRole.PRODUCER,
      AuthRole.RISK_MANAGER,
      // AuthRole.SALES_MANAGER,
    ],
  },
  [AppType.CONVERSION]: {
    label: 'Conversion',
    description: 'Manage BORs and producer onboarding',
    href: '/conversion',
    type: AppType.CONVERSION,
    icon: ConversionIcon,
    showInMenu: false,
    scopes: [AuthRole.CONVERSION, AuthRole.ENGINEER],
  },
  [AppType.COVERAGE_GAP_ANALYSIS]: {
    label: 'Coverage gap analysis',
    description: 'Analyze coverage gaps',
    href: '/coverage-gap-analysis',
    type: AppType.COVERAGE_GAP_ANALYSIS,
    icon: CoverageGapAnalysisIcon,
    showInMenu: true,
    scopes: [...PRODUCER_ROLES, AuthRole.ENGINEER, AuthRole.PRODUCER_MANAGER, AuthRole.ACCOUNT_MANAGER],
  },
  [AppType.EXPOSURES]: {
    label: 'Exposures',
    description: 'View and edit exposure data',
    href: '/exposures',
    type: AppType.EXPOSURES,
    icon: ExposuresIcon,
    showInMenu: false,
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      // AuthRole.GROWTH_CALLER,
      AuthRole.OPERATIONS,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.PRODUCER,
      AuthRole.RISK_MANAGER,
      AuthRole.SALES_MANAGER,
    ],
  },
  [AppType.FILES]: {
    label: 'Files',
    description: 'Manage file uploads and emails',
    href: '/files',
    type: AppType.FILES,
    icon: FilesIcon,
    showInMenu: false,
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      AuthRole.OPERATIONS,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.PRODUCER,
      AuthRole.RISK_MANAGER,
      AuthRole.SALES_MANAGER,
    ],
  },
  [AppType.QUOTING]: {
    label: 'Quoting',
    description: 'Manage submissions and create proposals',
    href: '/quoting',
    type: AppType.QUOTING,
    icon: QuotingIcon,
    showInMenu: false,
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      // AuthRole.GROWTH_CALLER,
      AuthRole.OPERATIONS,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.PRODUCER,
      // AuthRole.RISK_MANAGER,
      // AuthRole.SALES_MANAGER,
    ],
  },
  [AppType.TRADING_PARTNERS]: {
    label: 'Trading partners',
    description: 'Find trading partner and contact information',
    href: '/trading-partners/list',
    type: AppType.TRADING_PARTNERS,
    icon: TradingPartnersIcon,
    showInMenu: true,
    scopes: [AuthRole.ENGINEER, AuthRole.CARRIER_RELATIONS, AuthRole.TRADING_PARTNERS_READONLY, ...EMPLOYEE_ROLES],
  },
  [AppType.BILLING]: {
    label: 'Billing',
    description: 'Manage invoices',
    href: '/billing',
    type: AppType.BILLING,
    icon: BillingIcon,
    showInMenu: true,
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      AuthRole.OPERATIONS,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.PRODUCER,
      AuthRole.RISK_MANAGER,
      AuthRole.SALES_MANAGER,
    ],
  },
};

/**
 * Get all of the available app types.
 * @returns Array of app types
 */
export function getAppTypes(): AppType[] {
  return Object.values(AppType);
}

/**
 * Returns the metadata for an app.
 * @param type AppType
 * @returns AppMetadata
 */
export function getAppTypeMetadata(type: AppType): AppMetadata {
  const metadata = APP_METADATA[type];
  if (!metadata) {
    throw new Error(`Invalid app type: ${type}`);
  }
  return metadata;
}

/**
 * Determine if the user can access an app using their roles.
 * @param type AppType
 * @param userRoles Array of roles from the user object
 * @returns boolean
 */
export function canViewApp(type: AppType, userRoles: string[]): boolean {
  const { scopes } = getAppTypeMetadata(type);
  return hasAnyScope(userRoles, scopes);
}
