import * as React from 'react';

import { cn } from '../../v2';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export function ModalFooter(props: Props): JSX.Element {
  const { children, className } = props;

  return <div className={cn('flex justify-end border-t p-6', className)}>{children}</div>;
}
