import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import * as React from 'react';
import { useState } from 'react';

import { IconButton } from '../../components/icon-button';
import { IconSearch } from '../../components/icons';
import { generateTestId } from '../../lib/test-helpers';
import { cn } from '../lib/utils';

const inputVariants = cva(
  cn(
    'flex h-10 w-full flex-row overflow-hidden rounded-sm bg-background text-base',
    'border text-foreground focus-within:border-brand-400 hover:focus-within:border-brand-400',
  ),
  {
    variants: {
      variant: {
        primary: '',
        withAiSuggestion: 'border-purple-500 bg-purple-100 dark:bg-purple-500',
      },
      isInvalid: {
        true: 'border-fire-500',
      },
      disabled: {
        true: 'bg-muted',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

interface InputProps extends React.ComponentProps<'input'>, VariantProps<typeof inputVariants> {
  appendText?: string;
  prependText?: string;
  testId?: string;
  fieldId?: string; // Used for id and to generate test-id (if not provided) on input
  disabled?: boolean;
  appendIcon?: React.ReactNode;
  wrapperClassName?: string;
  inputClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      appendText,
      prependText,
      onChange,
      disabled,
      testId: propTestId,
      fieldId,
      value,
      isInvalid,
      variant,
      appendIcon,
      wrapperClassName,
      inputClassName,
      ...props
    },
    ref,
  ) => {
    const testId = propTestId ?? generateTestId(fieldId, 'input');
    const [passwordIsVisible, setPasswordIsVisible] = useState<boolean>(false);
    // Used to toggle between password and text type to show password
    const updatedType = type === 'password' && passwordIsVisible ? 'text' : type;
    return (
      <div className={cn(inputVariants({ isInvalid, disabled, variant, className: wrapperClassName }))}>
        {prependText && (
          <div className="flex shrink-0 items-center justify-center border-r px-2 text-steel-300">{prependText}</div>
        )}
        {type === 'search' && (
          <div className="flex items-center justify-center pl-3 text-steel-500">
            <IconSearch size={16} />
          </div>
        )}
        <input
          data-testid={testId}
          id={fieldId}
          type={updatedType}
          className={cn(
            `
            flex w-full flex-grow bg-transparent px-3
            py-2 ring-offset-background file:border-0 file:bg-transparent
            file:text-sm file:font-medium file:text-foreground
            placeholder:font-light placeholder:text-steel-300 focus-visible:outline-none disabled:cursor-not-allowed
            disabled:opacity-50
            `,
            inputClassName,
          )}
          aria-invalid={isInvalid ?? undefined}
          ref={ref}
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
        {appendText && (
          <div className="flex shrink-0 items-center justify-center border-l px-2 text-steel-300">{appendText}</div>
        )}
        {type === 'password' && (
          <div className="flex items-center justify-center pr-3 ">
            <IconButton
              type={passwordIsVisible ? 'hidePassword' : 'showPassword'}
              onClick={() => setPasswordIsVisible(!passwordIsVisible)}
              ariaLabel={passwordIsVisible ? 'Hide password' : 'Show password'}
              title={passwordIsVisible ? 'Hide password' : 'Show password'}
            />
          </div>
        )}
        {appendIcon && <div className="flex items-center justify-center pr-3">{appendIcon}</div>}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
