/* eslint-disable react/no-array-index-key */
import type { CalendarDate } from '@newfront-insurance/string-formatters';

import { DayCell, EmptyDayCell } from './day';
import type { CalendarHook } from '../hooks/calendar';

const labels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

interface Props {
  onClick: (date: CalendarDate) => unknown;
  calendarHook: CalendarHook;
  validate?: (date: CalendarDate) => boolean;
  selectedDate?: CalendarDate;
}

export default function CalendarMonth(props: Props): JSX.Element {
  const { calendarHook, onClick, validate, selectedDate } = props;
  const { calendar } = calendarHook;

  return (
    <div>
      <table className="w-full border-collapse" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            {labels.map((label) => (
              <th className="h-[30px] pt-[5px]" key={label}>
                <span className="text-base font-semibold">{label}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {calendar.map((week, index) => (
            <tr key={index}>
              {week.map((calendarDay) => {
                const isValid = validate ? validate(calendarDay) : true;
                const isSelected = selectedDate && calendarDay.isSameDate(selectedDate);
                const isCurrentMonth = calendarDay.getMonth() === calendarHook.month;

                return (
                  <DayCell
                    day={calendarDay}
                    isSelected={isSelected}
                    isDisabled={!isValid}
                    isCurrentMonth={isCurrentMonth}
                    key={calendarDay.toString()}
                    onClick={() => {
                      onClick(calendarDay);
                    }}
                  />
                );
              })}
            </tr>
          ))}
          {calendar.length === 5 && (
            <tr>
              {[...Array(7)].map((_, index) => (
                <EmptyDayCell key={`empty-day-${index}`} />
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
