import kebabCase from 'lodash/kebabCase';
import type { CSSProperties } from 'react';

import { cn } from '../../v2';
import { CheckboxInput } from '../checkbox-input';

interface Props {
  id?: string;
  label: string;
  isActive?: boolean;
  onClick?: (isActive: boolean) => unknown;
  disabled?: boolean;
  count?: number;
  testId?: string;
  style?: CSSProperties;
  labelClassName?: string;
  disabledLabelClassName?: string;
}

export function CheckboxRow({
  id,
  label,
  isActive,
  disabled,
  onClick,
  count,
  style = {},
  testId: propTestId,
  labelClassName,
  disabledLabelClassName,
}: Props): JSX.Element {
  const testId = propTestId ?? id ?? kebabCase(label);

  return (
    <div className="flex" style={style}>
      <CheckboxInput
        id={id}
        testId={testId}
        checked={isActive}
        onChange={(e) => {
          onClick?.(e.target.checked);
        }}
        disabled={disabled}
      />
      <div className="pl-2 pr-1">
        <label htmlFor={id}>
          <span
            className={cn('cursor-pointer text-base', labelClassName, {
              [disabledLabelClassName ?? '']: disabled,
            })}
          >
            {label}
          </span>
        </label>
      </div>
      {count ? <span className="text-base text-steel-300">({count})</span> : null}
    </div>
  );
}
