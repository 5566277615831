import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { IconPlus, IconLoadingSpinner } from '../icons';
import { cn } from '../lib/utils';

const buttonVariants = cva(
  cn(
    'inline-flex items-center justify-center',
    'whitespace-nowrap rounded-[4px] text-base font-semibold',
    'ring-offset-background transition-colors',
    'focus-visible:outline-none focus-visible:ring-2',
    'focus-visible:ring-ring focus-visible:ring-offset-2',
    'disabled:pointer-events-none disabled:opacity-50',
    'font-sans leading-5',
  ),
  {
    variants: {
      variant: {
        primary: cn(
          'border border-brand-400 bg-brand-400 text-white',
          'hover:border-brand-300 hover:bg-brand-300',
          'disabled:border-steel-200 disabled:bg-steel-200',
          'active:border-brand-400 active:bg-brand-400',
        ),
        secondary: cn(
          'text-brand-500 border bg-background',
          'hover:border-brand-300',
          'disabled:bg-steel-100 disabled:text-steel-400',
          'active:border-brand-400 active:text-brand-400',
        ),
        tertiary: cn('text-brand-400', 'hover:text-brand-300', 'disabled:text-steel-200', 'active:text-brand-400'),
        transparent: cn('text-steel-500', 'hover:text-steel-500', 'disabled:text-steel-200', 'active:text-steel-500'),
        // This one is not in the design system, but it's used in the app
        dangerous: cn(
          'border border-fire-500 bg-fire-500 text-white',
          'hover:border-fire-400 hover:bg-fire-400',
          'disabled:border-steel-200 disabled:bg-steel-200',
        ),
        newfrontPink: cn(
          'border border-rebrand-400 bg-rebrand-400 text-primary-foreground',
          'hover:border-rebrand-300 hover:bg-rebrand-300',
          'disabled:border-steel-200 disabled:bg-steel-200',
          'active:border-rebrand-400 active:bg-rebrand-400',
        ),
      },
      size: {
        default: 'h-10 px-8 py-3',
        small: 'h-7 px-3 py-1 text-sm',
      },
      loading: {
        true: 'cursor-wait',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

export interface ButtonDropzoneProps extends ButtonProps {
  icon?: JSX.Element;
}

export interface ButtonLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, loading, variant, size, asChild = false, disabled, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className, loading }))}
        ref={ref}
        disabled={disabled || loading}
        {...props}
      >
        {loading && <IconLoadingSpinner className="mr-3 text-steel-400" />}
        {children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

const ButtonDropzone = React.forwardRef<HTMLButtonElement, ButtonDropzoneProps>(
  ({ children, icon = <IconPlus />, className, ...props }, ref) => {
    return (
      <Button
        variant="tertiary"
        className={cn(
          'border border-dashed border-steel-200 bg-white text-brand-400',
          'hover:border-brand-300',
          'h-10 py-6 disabled:border-steel-200 disabled:text-steel-200',
          className,
        )}
        ref={ref}
        {...props}
      >
        <div className="pr-2">{icon}</div>
        {children}
      </Button>
    );
  },
);
ButtonDropzone.displayName = 'ButtonDropzone';

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ className, children, variant, size, ...props }, ref) => {
    return (
      <a className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        {children}
      </a>
    );
  },
);
ButtonLink.displayName = 'ButtonLink';

export { Button, ButtonDropzone, ButtonLink, buttonVariants };
