import { isNotEmpty } from '@newfront-insurance/core';
import { IconButton, MultiObjectSelector, colors, useFuzzy } from '@newfront-insurance/core-ui';
import { LineOfCoverageStageFilter } from '@newfront-insurance/coverage-api';
import { useState, useEffect, useRef } from 'react';

import { LineOfCoverageCard } from '../../components/line-of-coverage-card';
import { useLinesOfCoverage } from '../../queries/use-lines-of-coverage';
import type { EnrichedLoc } from '../../types';

interface Props {
  onChange: (value: string[]) => void;
  accountUuid: string;
  initLineOfCoverageUuids?: string[];
  stage?: LineOfCoverageStageFilter;
  disabled?: boolean;
  isCompact?: boolean;
}

export function LineOfCoverageSelector({
  onChange,
  accountUuid,
  initLineOfCoverageUuids,
  stage = LineOfCoverageStageFilter.BOUNDED,
  disabled = false,
  isCompact = false,
}: Props): JSX.Element {
  const { data: lineOfCoverageCandidates, isLoading } = useLinesOfCoverage(
    {
      accountUuid: accountUuid!,
      stage,
    },
    {
      // miniminze refetching when no needed
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const [selectedLinesOfCoverage, setSelectedLinesOfCoverage] = useState<EnrichedLoc[]>([]);

  useEffect(() => {
    if (isNotEmpty(initLineOfCoverageUuids)) {
      const initLineOfCoverageUuidsSet = new Set(initLineOfCoverageUuids);
      const initLinesOfCoverage =
        lineOfCoverageCandidates?.filter((loc) => initLineOfCoverageUuidsSet.has(loc.uuid)) ?? [];
      setSelectedLinesOfCoverage(initLinesOfCoverage);
    }
  }, [lineOfCoverageCandidates, initLineOfCoverageUuids]);

  const { results, search } = useFuzzy(lineOfCoverageCandidates ?? [], {
    keys: ['title', 'displayName', 'policy.policyNumber', 'policy.carrier.name', 'lineOfCoverageStatus'],
  });

  const handleSelectLinesOfCoverage = (locs: EnrichedLoc[]) => {
    setSelectedLinesOfCoverage(locs);
    onChange(locs.map((loc) => loc.uuid));
  };
  const lineOfCoverageSelectorRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={lineOfCoverageSelectorRef}>
      <MultiObjectSelector<EnrichedLoc>
        items={results}
        itemToString={(loc) => loc?.displayName ?? ''}
        getItemKey={(loc) => loc?.uuid ?? ''}
        renderItem={(loc, { isHovering, isSelected }) => (
          <LineOfCoverageCard
            lineOfCoverage={loc}
            isHighlighted={isSelected}
            isHovering={isHovering}
            cardProps={{
              hideBorder: true,
            }}
            defaultExpanded={false}
          />
        )}
        renderSelectedItems={(loc, { remove, isRemovable }) => (
          <LineOfCoverageCard
            lineOfCoverage={loc}
            actions={isRemovable ? [<IconButton key="close-button" type="close" onClick={remove} />] : []}
            isExpandable
            defaultExpanded={false}
            cardProps={{
              hideBorder: true,
              backgroundColor: colors.steel[50],
              padding: isCompact ? 'none' : 'default',
            }}
            isCompact={isCompact}
          />
        )}
        renderItemHover={(loc) => (
          <div
            style={{
              width: 460,
            }}
          >
            <LineOfCoverageCard defaultExpanded lineOfCoverage={loc} />
          </div>
        )}
        onSelect={(locs) => handleSelectLinesOfCoverage(locs)}
        selectedItems={selectedLinesOfCoverage}
        isLoading={isLoading}
        onSearch={search}
        disabled={disabled}
        isCompact={isCompact}
        popoverPortalElement={lineOfCoverageSelectorRef.current?.parentElement ?? undefined}
      />
    </div>
  );
}
