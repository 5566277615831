import * as React from 'react';
import type { StyledComponentProps } from 'styled-components';

import { cn } from '../../v2';
import { CircleSpinnerInline } from '../spinner';

type StyledProps = StyledComponentProps<'button', object, object, never>;

interface Props extends StyledProps {
  showing: boolean;
  isLoading?: boolean;
  onClick: (mouseEvent?: React.MouseEvent<HTMLButtonElement>) => unknown;
  testId?: string;
}

export default function ToggleDetailsButton({ showing, isLoading = false, onClick, testId }: Props): JSX.Element {
  return (
    <button
      className={cn(
        'box-border h-6 border-0 bg-transparent text-base outline-0',
        '-ml-2 px-2 py-[2px] text-steel-400 dark:text-steel-200',
        'inline-flex items-center rounded-sm ',
        {
          'pointer-events-none': isLoading,
          'hover:bg-steel-100 dark:hover:bg-steel-500': !isLoading,
        },
      )}
      type="button"
      onClick={onClick}
      data-testid={testId}
    >
      {showing ? 'Hide details' : 'Show details'}{' '}
      {!isLoading && (
        <div className={cn('ml-2 flex transform items-center outline-0', showing ? 'rotate-0' : 'rotate-180')}>
          <Icon />
        </div>
      )}
      {isLoading && (
        <div className="ml-1">
          <CircleSpinnerInline />
        </div>
      )}
    </button>
  );
}

function Icon(): JSX.Element {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path d="M0 5.5L5 0.5L10 5.5H0Z" fill="currentColor" />
    </svg>
  );
}
