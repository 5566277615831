import { forwardRef } from 'react';
import type { TextareaAutosizeProps } from 'react-textarea-autosize';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { cn } from '../../v2';

export const TextareaAutosize = forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>(
  ({ className, ...props }, ref) => {
    return (
      <ReactTextareaAutosize
        ref={ref}
        className={cn(
          'w-full resize-none border',
          'shadow-[0px_2px_2px_rgba(153,177,190,0.2)]',
          'p-4 text-base',
          'box-border rounded-sm outline-0',
          'block dark:bg-background',
          'placeholder:text-steel-300',
          'focus:border-brand-400',
          className,
        )}
        {...props}
      />
    );
  },
);
