/* eslint-disable react/jsx-props-no-spreading */
import type { Placement } from '@popperjs/core';
import type { CSSProperties, ReactNode, RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { useOnClickOutside } from '../../hooks/click-outside';
import { zIndices } from '../../theme';
import { PopoverAnimation } from '../popover-animation';

interface PopperChildProps {
  toggle: (isOpen?: boolean) => void;
}
export interface PopperTriggerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggerRef?: RefObject<any>;
  toggle: (isOpen?: boolean) => void;
}
interface BaseProps {
  children: ReactNode | ((props: PopperChildProps) => ReactNode);
  trigger: (props: PopperTriggerProps) => ReactNode;
  offsetSkidding?: number;
  offsetDistance?: number;
  placement?: Placement;
  portalElement?: HTMLElement;
  style?: CSSProperties;
  testId?: string;
}

type UncontrolledProps = BaseProps;

interface LegacyPopperChildProps {
  toggle: () => void;
}
interface LegacyPopperTriggerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggerRef?: RefObject<any>;
  toggle: () => void;
}

interface LegacyProps extends BaseProps {
  isOpen?: boolean;
  onToggle?: () => void;
  trigger: (props: LegacyPopperTriggerProps) => ReactNode;
  children: ReactNode | ((props: LegacyPopperChildProps) => ReactNode);
}

interface ControlledProps extends BaseProps {
  isOpen?: boolean;
  onToggle: (isOpen?: boolean) => void;
}

export function ControlledPopper({
  isOpen,
  onToggle,
  children,
  trigger,
  offsetSkidding,
  offsetDistance = 8,
  placement = 'bottom-end',
  portalElement,
  style = {},
  testId,
}: ControlledProps): JSX.Element {
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);
  const triggerRef = useRef<HTMLElement>(null);
  const { attributes, update, styles } = usePopper(triggerRef.current, popoverRef, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offsetSkidding, offsetDistance],
        },
      },
    ],
  });

  const toggle = (newValue?: boolean): void => {
    if (typeof newValue === 'boolean') {
      onToggle(newValue);
    } else {
      onToggle(!isOpen);
    }
  };

  useEffect(() => {
    if (update) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      update();
    }
  }, [isOpen, update]);

  useOnClickOutside(popoverRef, (e) => {
    if (isOpen && e.target !== triggerRef.current) {
      toggle(false);
    }
  });

  const popoverContent = (
    <div
      {...attributes.popper}
      data-testid={testId}
      style={{
        ...styles.popper,
        width: style.width,
        zIndex: zIndices.popover,
      }}
      ref={setPopoverRef}
    >
      <PopoverAnimation direction="down" isOpen={isOpen ?? false}>
        <div className="rounded-sm border bg-background shadow-overlayShadow" style={style}>
          {typeof children === 'function' ? children({ toggle }) : children}
        </div>
      </PopoverAnimation>
    </div>
  );

  return (
    <>
      {trigger?.({ toggle, triggerRef })}
      {portalElement ? createPortal(popoverContent, portalElement) : popoverContent}
    </>
  );
}

export function UncontrolledPopper(props: UncontrolledProps): JSX.Element {
  const { children, ...rest } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ControlledPopper {...rest} isOpen={isOpen} onToggle={(newValue) => setIsOpen(newValue ?? false)}>
      {children}
    </ControlledPopper>
  );
}

/**
 * @deprecated Please use ControlledPopper or UncontrolledPopper instead
 */
export function Popper(props: LegacyProps): JSX.Element {
  const { onToggle, isOpen, children, testId, ...rest } = props;
  const isControlled = typeof isOpen !== 'undefined';

  if (isControlled) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const toggle = typeof onToggle === 'function' ? onToggle : () => {};
    return (
      <ControlledPopper {...rest} onToggle={toggle} isOpen={isOpen} testId={testId}>
        {children}
      </ControlledPopper>
    );
  }

  return (
    <UncontrolledPopper {...rest} testId={testId}>
      {children}
    </UncontrolledPopper>
  );
}
