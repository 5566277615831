import type { PublicAccount as ApiPublicAccount } from '@newfront-insurance/account-api';
import {
  ProgramType,
  BrokerOfRecord,
  AccountStatus,
  AccountStatusReason,
  AccountType,
  VerificationStatus,
} from '@newfront-insurance/account-api';
import omit from 'lodash/omit';

import { NOW } from './dates';
import { ACCOUNT_EMPLOYEE_ROLE_MAPPINGS } from './employees';
import { BUSINESS_IDENTIFIER, API_BUSINESS_IDENTIFIER } from './identities';
import { ACCOUNT_UUID, PRIMARY_PRODUCER_EMPLOYEE_UUID, PRIMARY_PRODUCER_UUID } from './uuids';
import type { PublicAccount } from '../bff/types';

export const API_PUBLIC_ACCOUNT: ApiPublicAccount = {
  affinityGroups: [],
  brokerOfRecord: BrokerOfRecord.NEWFRONT,
  dba: 'Lorem ipsum dba',
  identities: [API_BUSINESS_IDENTIFIER],
  name: 'John Doe',
  primaryProducerEmployeeUuid: PRIMARY_PRODUCER_EMPLOYEE_UUID,
  primaryProducerUuid: PRIMARY_PRODUCER_UUID,
  programType: ProgramType.PROPERTY_AND_CASUALTY,
  clientAccessFeatures: { claims: true },
  status: {
    status: AccountStatus.ACTIVE,
    statusReason: AccountStatusReason.CREATED,
    statusDate: NOW as unknown as Date,
  },
  type: AccountType.COMMERCIAL,
  uuid: ACCOUNT_UUID,
  verificationStatus: VerificationStatus.PENDING,
};

export const PUBLIC_ACCOUNT: PublicAccount = {
  ...omit(API_PUBLIC_ACCOUNT, 'identities'),
  uuid: API_PUBLIC_ACCOUNT.uuid as string,
  businessIdentifier: BUSINESS_IDENTIFIER,
  employeeRoleMappings: ACCOUNT_EMPLOYEE_ROLE_MAPPINGS,
  personalIdentities: [],
  status: {
    status: AccountStatus.ACTIVE,
    statusReason: AccountStatusReason.CREATED,
    statusDate: NOW,
  },
};
