/* eslint-disable @typescript-eslint/no-explicit-any */

import { isNotNil } from '@newfront-insurance/core';
import { cn } from '@newfront-insurance/core-ui/v2';
import { InputType } from '@newfront-insurance/dsl-schema-api';
import find from 'lodash/find';

import type { CustomValue, ValueProps } from './registry';
import { customValueComponentsRegistry } from './registry';
import { getStringifiedFieldValue } from '../utils/getStringifiedFieldValue';

export function Value({
  name,
  value,
  field,
  accountUuid,
  size = 'regular',
  color = 'dark',
  weight = 300,
}: ValueProps): JSX.Element {
  const testId = `Value-${name}`;
  let displayValue;

  const { inputType: type, inputMetadata: metadata } = field;

  const textClassName = cn('text-base text-foreground', {
    'font-light': weight === 300,
    'font-normal': weight === 400,
    'font-medium': weight === 500,
    'font-semibold': weight === 600,
    'font-bold': weight === 700,
    'text-sm': size === 'small',
    'text-base': size === 'regular',
    'text-lg': size === 'large',
    'text-xl': size === 'extraLarge',
    'text-foreground': color === 'dark', // Only dark is used throughout the codebase
  });

  switch (type) {
    // multi select is an exception for now because of the way it's displayed
    // we probably want to change it to display inline, comma separated, but for now it should be fine.
    case InputType.MULTI_SELECT: {
      return (
        <div className="flex flex-col gap-0.5">
          {value?.map((item: string) => {
            const option = find(metadata?.options, { value: item });
            const key = `${testId}-${item}`;
            return (
              <span
                className={textClassName}
                data-testid={key}
                key={key}
                title={`${option?.label} ${option?.description ?? ''}`}
              >
                {`${option?.label} ${option?.description ?? ''}`}
              </span>
            );
          }) || <span className={textClassName}>-</span>}
        </div>
      );
    }

    default: {
      // Check if there is a custom component for the type and use it if that is the case
      const CustomComponent = customValueComponentsRegistry[type]?.component;

      if (CustomComponent) {
        return (
          <CustomComponent
            name={name}
            value={value}
            field={field}
            size={size}
            color={color}
            weight={weight}
            accountUuid={accountUuid}
          />
        );
      }

      // fallback
      displayValue = getStringifiedFieldValue(value, field);

      break;
    }
  }

  return (
    <span className={cn(textClassName, 'whitespace-pre-wrap')} data-testid={testId} title={displayValue || '-'}>
      {displayValue || '-'}
    </span>
  );
}

/**
 * Allows to extend Value by adding new inputType handlers
 * @param inputType
 * @param customValue
 */
Value.registerComponent = (
  inputType: string,
  customValue: CustomValue<any>,
  customGetStringifiedFieldValue?: (value: any, field?: any) => string | null,
) => {
  const previouslyRegisteredComponent = customValueComponentsRegistry[inputType];

  if (isNotNil(previouslyRegisteredComponent)) {
    // eslint-disable-next-line no-console
    console.warn(`Value.registerComponent for type "${inputType}" override. It was already set.`);
  }

  customValueComponentsRegistry[inputType] = {
    component: customValue,
    getStringifiedFieldValue: customGetStringifiedFieldValue,
  };
};
