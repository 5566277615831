/* eslint-disable @typescript-eslint/no-explicit-any */
import { CalendarDatePickerInput } from '@newfront-insurance/core-ui';
import { CalendarDate } from '@newfront-insurance/string-formatters';
import { useCallback } from 'react';

interface Props {
  name: string;
  value?: string;
  setValue: (value?: string, shouldValidate?: boolean | undefined) => void;
  disabled?: boolean;
  isCompact?: boolean;
}

export function DateField({ name, value, setValue, disabled, isCompact }: Props): JSX.Element {
  const onChange = useCallback(
    (newValue?: CalendarDate): void => {
      if (!newValue) {
        setValue(undefined);
        return;
      }
      setValue(newValue.toDate('UTC').toISOString());
    },
    [setValue],
  );

  return (
    <CalendarDatePickerInput
      name={name}
      value={value ? CalendarDate.fromDate(new Date(value)) : undefined}
      onChange={onChange}
      disabled={disabled}
      className={isCompact ? 'h-[30px]' : undefined}
    />
  );
}
