import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { generateTestId } from '../../lib/test-helpers';
import { cn } from '../../v2';
import type { IconSize } from '../icons';
import { IconCheckActive, IconCircleInformation, IconCross, IconWarning } from '../icons';

export type MessageBoxVariant = VariantProps<typeof messageBoxVariants>['variant'];

interface Props extends VariantProps<typeof messageBoxVariants> {
  message: string | JSX.Element;
  boxClassName?: string;
  textClassName?: string;
  action?: {
    text: string;
    onClick: (e?: unknown) => unknown;
  };
  customAction?: JSX.Element;
  testId?: string;
}

const MessageBoxIcon = ({
  size,
  color,
  variant,
}: {
  size: IconSize;
  color: string;
  variant: VariantProps<typeof iconVariants>['variant'];
}) => {
  switch (variant) {
    case 'alert':
      return <IconWarning size={size} color={color} />;
    case 'success':
      return <IconCheckActive size={size} color={color} />;
    case 'neutral':
      return <IconCircleInformation size={size} color={color} />;
    case 'warning':
      return <IconWarning size={size} color={color} />;
    case 'disabled':
      return <IconCross size={size} color={color} />;
    default:
      return null;
  }
};

type IconComponent = (props: {
  size: IconSize;
  color: string;
  variant: VariantProps<typeof iconVariants>['variant'];
}) => JSX.Element | null;

export const VariantIcon = ({
  variant,
  icon,
}: VariantProps<typeof iconVariants> & { icon: IconComponent }): JSX.Element | null => {
  if (variant === null) {
    return null;
  }

  const Icon = icon;

  return (
    <span className={cn(iconVariants({ variant }))}>
      <Icon size={16} color="currentColor" variant={variant} />
    </span>
  );
};

const messageBoxVariants = cva(cn('rounded-sm border text-base'), {
  variants: {
    variant: {
      alert: cn('border-fire-500 bg-[#FFF6F8] text-fire-500 dark:bg-[#341A29] dark:text-fire-400'),
      success: cn('border-grass-400 bg-[#F1FBF7] text-grass-600 dark:bg-[#104731] dark:text-grass-300'),
      neutral: cn('border-brand-400 bg-brand-100 text-brand-400 dark:bg-[#142546] dark:text-brand-200'),
      warning: cn('border-peach-500 bg-fire-100 text-[#B37855] dark:bg-[#5A410F] dark:text-peach-500'),
      disabled: cn('border-steel-400 bg-steel-100 text-steel-400 dark:bg-steel-400 dark:text-steel-300'),
    },
  },
  defaultVariants: {
    variant: 'success',
  },
});

const iconVariants = cva('', {
  variants: {
    variant: {
      alert: 'text-fire-500',
      success: 'text-grass-500',
      neutral: 'text-brand-300',
      warning: 'text-peach-500',
      disabled: 'text-steel-400',
    },
  },
  defaultVariants: {
    variant: 'success',
  },
});

export const MessageBoxBox = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { variant?: MessageBoxVariant }
>(({ className, children, variant, ...props }, ref) => {
  return (
    <div className={cn(messageBoxVariants({ variant }), className)} ref={ref} {...props}>
      {children}
    </div>
  );
});

export function MessageBox({
  variant,
  message,
  action,
  customAction,
  boxClassName,
  textClassName,
  testId: propTestId,
}: Props): JSX.Element {
  const testId = propTestId ?? generateTestId(variant ?? 'success', 'message-box');

  return (
    <MessageBoxBox variant={variant} className={boxClassName} data-testid={testId}>
      <div className="p-4">
        <div className="flex items-center justify-between">
          <div>
            <div className="flex flex-row items-center gap-2">
              <VariantIcon variant={variant} icon={MessageBoxIcon} />
              <div>
                <span className={cn('block', textClassName)}>{message}</span>
              </div>
            </div>
          </div>

          {action && (
            <div>
              <div className="pr-4">
                <span className="block">
                  <button
                    className="m-0 cursor-pointer border-none bg-none p-[0.125rem] focus:outline focus:outline-fire-400 active:outline active:outline-fire-400"
                    type="button"
                    onClick={action.onClick}
                    data-testid={generateTestId(testId, 'action')}
                  >
                    <span className="font-semibold">{action.text}</span>
                  </button>
                </span>
              </div>
            </div>
          )}
          {customAction && (
            <div>
              <div className="pr-4">{customAction}</div>
            </div>
          )}
        </div>
      </div>
    </MessageBoxBox>
  );
}
