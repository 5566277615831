/* eslint-disable react/jsx-props-no-spreading */
import { useRef } from 'react';

import { colors } from '../../../theme/colors';
import { Flexbox } from '../../flexbox';
import { DefaultNoResultsDisplay, SelectInput, SimpleSelectValue, UnfocusedInput, VisibilityHidden } from '../common';
import { Selector } from '../common/selector';
import type { SingleSelectorProps } from '../common/types';
import { useSelector } from '../hooks';

export function SingleObjectSelector<T extends object>({
  aiSuggestedItemOrKey,
  disabled = false,
  emptyPlaceholder = 'Nothing selected',
  getDisabledProps,
  getItemKey,
  isClearable = true,
  isLoading,
  items,
  itemToString = (item) => item?.toString() ?? '',
  name,
  onSearch,
  onSelect,
  placeholder = 'Filter by keyword',
  renderFooter,
  renderInputToolbar,
  renderItem,
  renderItemHover,
  renderNoResults = DefaultNoResultsDisplay,
  renderSelectedItem,
  renderSelectedItemHover,
  selectedItem: selectedItemProp,
  testId,
  height,
}: SingleSelectorProps<T>): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    getComboboxProps,
    getMenuProps,
    getItemProps,
    getInputProps,
    getToggleButtonProps,
    isOpen,
    selectedItem,
    highlightedIndex,
    selectedItemKey,
    openMenu,
    closeMenu,
    clear,
  } = useSelector({
    items,
    itemToString,
    selectedItem: selectedItemProp as T,
    onSelectedItemChange: (changes) => onSelect(changes.selectedItem ?? null, changes),
    getItemKey,
    inputRef,
    getDisabledProps,
    onSearch,
  });

  const isUsingAISuggestedValue =
    typeof aiSuggestedItemOrKey !== 'string' && aiSuggestedItemOrKey
      ? getItemKey(aiSuggestedItemOrKey) === selectedItemKey
      : false;
  const isUsingAISuggestItemKey =
    typeof aiSuggestedItemOrKey === 'string' && aiSuggestedItemOrKey ? aiSuggestedItemOrKey === selectedItemKey : false;

  return (
    <Selector<T>
      emptyPlaceholder={emptyPlaceholder}
      getComboboxProps={getComboboxProps}
      getDisabledProps={getDisabledProps}
      getIsSelectedItem={(item) => getItemKey(item) === selectedItemKey}
      getItemKey={getItemKey}
      getItemProps={getItemProps}
      getMenuProps={getMenuProps}
      getToggleButtonProps={getToggleButtonProps}
      highlightedIndex={highlightedIndex}
      inputRef={inputRef}
      isLoading={isLoading}
      isOpen={isOpen}
      items={items}
      placeholder={placeholder}
      renderInputToolbar={renderInputToolbar}
      renderItem={renderItem}
      height={height}
      header={
        <div>
          {renderInputToolbar && (
            <Flexbox
              style={{
                borderBottom: `1px solid ${colors.steel[100]}`,
              }}
              padding={12}
            >
              {renderInputToolbar({
                focusInput: () => inputRef.current?.focus(),
              })}
            </Flexbox>
          )}
        </div>
      }
      renderInput={({ triggerRef }) => (
        <div ref={triggerRef}>
          <VisibilityHidden enabled={!isOpen}>
            <SelectInput
              {...getInputProps({
                placeholder,
                onClick: openMenu,
                ref: inputRef,
                disabled,
                name: isOpen ? name : undefined,
              })}
              inputRef={inputRef}
            />
          </VisibilityHidden>

          {!isOpen && (
            <UnfocusedInput
              withAIStyling={isUsingAISuggestedValue || isUsingAISuggestItemKey}
              getToggleButtonProps={(props) => getToggleButtonProps({ ...props, disabled, name })}
              emptyPlaceholder={emptyPlaceholder}
              isLoading={isLoading}
              itemToString={itemToString}
              placeholder={placeholder}
              hasValue={!!selectedItem}
              isOpen={isOpen}
              renderSelectedItem={() =>
                renderSelectedItem?.(selectedItem as T) ?? (
                  <SimpleSelectValue>{itemToString(selectedItem)}</SimpleSelectValue>
                )
              }
              renderSelectedItemHover={() => renderSelectedItemHover?.(selectedItem as T)}
              onClear={clear}
              isClearable={disabled ? false : isClearable}
              testId={testId}
              disabled={disabled}
            />
          )}
        </div>
      )}
      renderItemHover={renderItemHover}
      renderNoResults={renderNoResults}
      menuFooter={renderFooter?.(selectedItem as T, { closeMenu })}
      testId={testId}
    />
  );
}
