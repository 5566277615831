import kebabCase from 'lodash/kebabCase';
import * as React from 'react';
import { useMemo } from 'react';
import { uid } from 'uid';

import { generateTestId } from '../../lib/test-helpers';
import { cn } from '../../v2';

interface Props {
  children?: React.ReactNode;
  defaultChecked?: boolean;
  disabled?: boolean;
  isChecked?: boolean;
  label: string;
  name: string;
  onChange?: () => unknown;
  testId?: string;
  value: string | number;
  inputClassName?: string;
  labelClassName?: string;
}

export interface SelectableOption {
  label: string;
  value: string | number;
  testId?: string;
  inputClassName?: string;
}

export function RadioButton({
  children,
  defaultChecked,
  disabled,
  isChecked,
  label,
  name,
  onChange,
  testId: propTestId,
  value,
  inputClassName,
  labelClassName,
}: Props): JSX.Element {
  const id = useMemo(() => uid(), []);
  const testId = propTestId ?? generateTestId(kebabCase(label), 'radio-button');
  return (
    <label htmlFor={id} className="h-fit">
      <div className="flex flex-row items-center">
        <input
          checked={isChecked}
          data-testid={testId}
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          readOnly={!onChange}
          type="radio"
          value={value}
          className={cn(
            'h-5 w-5 flex-shrink-0 appearance-none rounded-full border',
            'checked:bg-blue-500 checked:border-blue-500',
            'checked:border-none focus:border-none focus:checked:border-none',
            'focus:outline-none',
            'checked:shadow-[inset_0_0_0_8px_theme(colors.brand.400)]',
            'focus:shadow-[0_0_8px_0.5px_theme(colors.brand.400)]',
            'focus:checked:shadow-[0_0_8px_0.5px_theme(colors.brand.400),inset_0_0_0_8px_theme(colors.brand.400)]',
            'transition-shadow duration-300 ease-in-out',
            inputClassName,
          )}
        />

        <span className="relative box-border inline-block cursor-pointer pl-2 leading-6">
          <div className="flex flex-col gap-1">
            <span
              className={cn(
                'text-base',
                {
                  'text-steel-300': disabled && !isChecked,
                },
                labelClassName,
              )}
            >
              {label}
            </span>
            {isChecked && children ? children : null}
          </div>
        </span>
      </div>
    </label>
  );
}
