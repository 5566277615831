import type { SyntheticEvent } from 'react';
import * as React from 'react';
import { forwardRef } from 'react';

import { cn } from '../../../v2';
import { IconExternal2 as IconExternal } from '../../icons';

interface Props {
  href?: string;
  isDisabled?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  target?: string;
  testId?: string;
  children: React.ReactNode;
}

export const MenuItemLink = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const { href, isDisabled = false, onClick, target = '_self', testId, children } = props;
  const isExternal = href?.startsWith('http') || target === '_blank';

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      ref={ref}
      aria-disabled={isDisabled}
      tabIndex={0}
      className={cn(
        'relative w-full cursor-pointer whitespace-nowrap text-base leading-10 text-muted-foreground',
        'no-underline focus:outline-none focus-visible:bg-brand-100 focus-visible:text-brand-400',
        'flex flex-row items-center gap-2 pl-6 pr-10',
        {
          'pointer-events-none cursor-not-allowed text-steel-200 dark:text-steel-400': isDisabled,
          'hover:bg-brand-100 hover:text-brand-400 dark:hover:bg-steel-400 dark:hover:text-foreground': !isDisabled,
          isDisabled,
          isExternal,
        },
      )}
      onClick={onClick}
      role="menuitem"
      target={isExternal ? '_blank' : target}
      href={!isDisabled ? href : undefined}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick?.(event);
        }
      }}
      data-testid={testId}
      rel="noreferrer"
    >
      {children}
      {isExternal && <IconExternal />}
    </a>
  );
});
