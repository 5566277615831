import { Modal, ModalHeader, ModalBody, ModalFooter, ObjectSelector, Button, Stack } from '@newfront-insurance/core-ui';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import type { Policy } from '../../bff/types';
import { useFuzzy } from '../../utils/hooks/use-fuzzy';
import { PolicyCard } from '../policy-card';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  /** The error message to display */
  error?: string;
  onToggle: () => void;
  /** Array of Policies to display */
  policies: Policy[];
  /** Modal title */
  header: string;
  /** Function to define the text to show on the submit button. It receives the array of selected policies as a param */
  submitText: string | ((policyUuids: string[]) => ReactNode);
  /** If required, you will need at least one policy selected for Submit to become enabled */
  required?: boolean;
  onSubmit: (policyUuids: string[]) => void;
  submitting?: boolean;
  /** Multi will display checkboxes, single will display radio buttons */
  type: 'multi' | 'single';
  /** An array of IDs of the policies that are already selected */
  initialSelectedPoliciesUuids: string[];
}

export function PolicySelectorModal({
  isOpen,
  onToggle,
  loading = false,
  error,
  policies,
  type,
  header,
  submitText,
  submitting = false,
  required = false,
  onSubmit,
  initialSelectedPoliciesUuids,
}: Props): JSX.Element {
  const {
    result: results,
    search,
    keyword,
  } = useFuzzy<Policy>(policies, {
    keys: ['type', 'name', 'policyNumber', 'details.displayName', 'carrier.name', 'details.thirdParty'],
  });

  const [selectedPoliciesUuids, setSelectedPoliciesUuids] = useState<string[]>(initialSelectedPoliciesUuids);

  useEffect(() => {
    setSelectedPoliciesUuids(initialSelectedPoliciesUuids);
  }, [initialSelectedPoliciesUuids]);

  function handleSelection(policy: Policy): void {
    if (type === 'multi') {
      setSelectedPoliciesUuids((currentSelectedPoliciesUuids) =>
        currentSelectedPoliciesUuids.includes(policy.uuid || '')
          ? currentSelectedPoliciesUuids.filter((policyUuid) => policyUuid !== policy.uuid)
          : currentSelectedPoliciesUuids.concat([policy.uuid ?? '']),
      );

      return;
    }

    setSelectedPoliciesUuids([policy.uuid ?? '']);
  }

  function isSelected(policy: Policy): boolean {
    return selectedPoliciesUuids.includes(policy.uuid ?? '');
  }

  function handleSubmit(): void {
    onSubmit(selectedPoliciesUuids);
  }

  return (
    <Modal isOpen={isOpen} onToggle={onToggle} width="600px">
      <ModalBody>
        <ModalHeader titleText={header} onClose={onToggle} />
        <ObjectSelector
          loading={loading}
          error={error}
          placeholder="No policies available"
          onSearchInputChange={search}
          searchInput={keyword}
        >
          {results.map((policy) => (
            <PolicyCard
              key={policy.uuid}
              policy={policy}
              onSelect={() => handleSelection(policy)}
              isSelected={isSelected(policy)}
              selectType={type === 'multi' ? 'checkbox' : 'radio'}
            />
          ))}
        </ObjectSelector>
        <ModalFooter>
          <Stack gap={8} direction="horizontal" justifyContent="flex-end">
            <Button onClick={onToggle} disabled={submitting} size="secondary">
              Cancel
            </Button>
            <Button
              loading={submitting}
              disabled={required ? selectedPoliciesUuids.length === 0 : false}
              onClick={handleSubmit}
            >
              {typeof submitText === 'function' ? submitText(selectedPoliciesUuids) : submitText}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}
