import React from 'react';

import { cn } from '../../lib/utils';

export const TextEyebrow = React.forwardRef<HTMLSpanElement, React.HTMLAttributes<HTMLSpanElement>>(
  ({ className, ...props }, ref) => (
    <span
      ref={ref}
      className={cn('m-0 font-sans text-sm font-semibold uppercase tracking-wide text-steel-300', className)}
      {...props}
    />
  ),
);
TextEyebrow.displayName = 'TextEyebrow';
