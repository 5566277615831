import type { ComponentProps, PropsWithChildren } from 'react';

export function ToolbarButton({ children, ...props }: PropsWithChildren & ComponentProps<'a'>): JSX.Element {
  return (
    <a
      className={`
         flex h-10 w-10 items-center justify-center rounded-full
         indent-[-9999px] text-steel-400 transition-all duration-200
         ease-in-out hover:cursor-pointer hover:bg-steel-100 focus:bg-steel-100
         focus:outline-none data-[active=true]:bg-steel-100
         dark:text-foreground dark:hover:bg-brand-400 dark:focus:bg-brand-400
         `}
      {...props}
    >
      {children}
    </a>
  );
}
